import React from 'react';
import styled from 'styled-components';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import { useMediaQuery } from '@material-ui/core';
import { APPSTORE_LINKS, BREAKPOINTS } from 'src/utils/constants';

interface IStyledProps {
  isTablet?: boolean;
}
const AppBannerContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: ${({ isTablet }: IStyledProps) => (isTablet && 'column') || 'row'};
  align-items: center;
  justify-content: center;
  background: #f7f7f7;
`;
const AppWrap = styled.div`
  display: flex;
  flex-direction: ${({ isTablet }: IStyledProps) => (isTablet && 'column') || 'row'};
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  padding: ${({ isTablet }: IStyledProps) => (isTablet && '0px') || '0 25px'};
`;

const AppTextWrap = styled.div`
  display: inline-block;
`;
const AppHeading = styled.h1`
  display: inline-block;
  color: #3fb865;
`;
const AppText = styled.div`
  font-size: 25px;
`;
const LogoSection = styled.div`
  display: inline-block;
`;
const AppIconsWrap = styled.div`
  padding: 15px;
  box-sizing: border-box;
`;
const GetApp = styled.h2`
  margin-left: 10px;
`;

const Logo = styled.img`
  width: 330px;
`;
const StoreIcon = styled.img`
  width: 100px;
  margin-left: 10px;
  cursor: pointer;
`;

const AppsBanner = () => {
  const isTablet = useMediaQuery(BREAKPOINTS.TABLETS);
  return (
    <AppBannerContainer isTablet={isTablet}>
      <AppWrap isTablet={isTablet}>
        <LogoSection>
          <Logo src="/assets/graphics/feature2.webp" alt="feature" />
        </LogoSection>
        <AppTextWrap>
          <AppHeading>TRY THE RENTABLE APP</AppHeading>
          <AppText>Turn Your Liabilities into Paying Assets</AppText>
        </AppTextWrap>
      </AppWrap>
      <Divider orientation="vertical" flexItem />
      <AppIconsWrap>
        <GetApp>GET YOUR APP TODAY</GetApp>
        <Link href={APPSTORE_LINKS.IOS} target="_blank" rel="noopener noreferrer">
          <LogoSection>
            <StoreIcon src="/assets/graphics/appstore.webp" alt="APP store logo" />
          </LogoSection>
        </Link>
        <Link href={APPSTORE_LINKS.ANDROID} target="_blank" rel="noopener noreferrer">
          <LogoSection>
            <StoreIcon src="/assets/graphics/playstore.webp" alt="PlayStore logo" />
          </LogoSection>
        </Link>
      </AppIconsWrap>
    </AppBannerContainer>
  );
};
export default AppsBanner;
