import React from 'react';
import styled from 'styled-components';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import MuiContainer from '@material-ui/core/Container';

const Container = styled(MuiContainer)<any>`
  background: ${({ theme }) => theme.colors.light.primary};
  padding: 30px 10px;
  display: flex !important;
  flex-direction: column;
  align-items: center;
`;
const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
`;
const Title = styled.h3`
  font-size: 30px;
  text-transform: uppercase;
  margin: 15px 0;
  text-transform: uppercase;
  @media (max-width: 450px) {
    font-size: 20px;
  }
`;
const SubTitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 15px;
  line-height: 1.5;
`;
const StepContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  @media (max-width: 450px) {
    flex-direction: column;
    align-items: flex-start;
  }
  .arrow-first {
    &:before {
      border-color: ${({ theme }) => theme.colors.primary} !important;
      content: ' ';
      width: calc(100% + 45px);
      height: calc(50% + 130px);
      top: -52%;
      border: 2px dotted transparent;
      position: absolute;
      border-radius: 50%;
      border-top-width: 0;
      border-left-width: 0;
      border-right-width: 0;
      left: calc(-50% - 23px);
      z-index: 1;
      @media (max-width: 450px) {
        border-color: transparent !important;
      }
    }
  }
  .arrow-second {
    &:before {
      border-color: ${({ theme }) => theme.colors.primary} !important;
      border-top-width: 2px;
      border-bottom-width: 0 !important;
      top: 4%;
      content: ' ';
      width: calc(100% + 45px);
      height: calc(50% + 130px);
      border: 2px dotted transparent;
      position: absolute;
      border-radius: 50%;
      border-left-width: 0;
      border-right-width: 0;
      left: calc(-50% - 23px);
      z-index: 1;
      @media (max-width: 450px) {
        border-color: transparent !important;
      }
    }
  }
`;
const StepBox = styled.div`
  position: relative;
  padding: 20px;
  text-align: center;
  margin-bottom: 50px;
  width: 33.32%;
  @media (max-width: 450px) {
    text-align: left;
    width: 100%;
    margin-bottom: 0px;
    padding: 0px;
    padding-bottom: 15px;
  }
`;
const Icon = styled.div`
  margin-bottom: 35px;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.light.primary};
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 30px;
  box-shadow: 0 0 0 10px ${({ theme }) => theme.colors.light.primary};
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  flex-shrink: 0;
  position: relative;
  z-index: 2;
`;
const StepContent = styled.div``;
const StepContentHeader = styled.h5`
  margin: 0;
  margin-bottom: 15px;
  font-size: 18px;
`;
const StepContentText = styled.p`
  margin: 0;
  line-height: 23px;
  opacity: 0.7;
  text-align: left;
`;
const UserIcon = styled(PersonOutlineOutlinedIcon)`
  color: white !important;
  position: relative;
  top: 3px;
`;
const NotificationIcon = styled(NotificationsActiveOutlinedIcon)`
  color: white !important;
  position: relative;
  top: 3px;
`;
const MoneyIcon = styled(MonetizationOnOutlinedIcon)`
  color: white !important;
  position: relative;
  top: 3px;
`;

const HowItWorks = () => {
  return (
    <Container component="section" maxWidth="lg">
      <Header>
        <Title>How It Works</Title>
        <SubTitle>Quick brief on how simple yet powerful Rentable is</SubTitle>
      </Header>
      <StepContainer>
        <StepBox>
          <Icon>
            <UserIcon fontSize="large" />
          </Icon>
          <StepContent>
            <StepContentHeader>Create An Account</StepContentHeader>
            <StepContentText>
              Start your journey by creating your very first account to gain cool options which will
              allow you to Rent
            </StepContentText>
          </StepContent>
        </StepBox>

        <StepBox className="arrow-first">
          <Icon>
            <NotificationIcon fontSize="large" />
          </Icon>
          <StepContent>
            <StepContentHeader>Post An Advert</StepContentHeader>
            <StepContentText>
              Now when you are our member post your very first advert and make sure you be
              descriptive as possible along with images
            </StepContentText>
          </StepContent>
        </StepBox>

        <StepBox className="arrow-second">
          <Icon>
            <MoneyIcon fontSize="large" />
          </Icon>
          <StepContent>
            <StepContentHeader>Start Earning</StepContentHeader>
            <StepContentText>
              Is your advert ready and live? Now sit back, enjoy and wait for that very first phone
              call and start earning money
            </StepContentText>
          </StepContent>
        </StepBox>
      </StepContainer>
    </Container>
  );
};

export default HowItWorks;
