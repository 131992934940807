import React from 'react';
import styled from 'styled-components';
import Container from '@material-ui/core/Container';
import MuiButton from '@material-ui/core/Button';
import { IListings } from 'src/interface';

import useGetFeaturedListings from 'src/hooks/api/listings/useGetFeaturedListings';
import ListingsSection from '../Components/ListingsSection';

const Section = styled('section')`
  padding: 40px 0;
  background: ${({ theme }) => theme.colors.plainWhite};
  @media (max-width: 450px) {
    padding: 10px 0;
  }
`;
const SectionContainer = styled(Container)`
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
`;
const SectionHeading = styled('h1')`
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
  @media (max-width: 450px) {
    font-size: 20px;
  }
`;
const StyledViewMore = styled(MuiButton)`
  margin: 20px 0 !important;
  padding: 10px 20px !important;
`;

const FeatureListings = ({ listings }: { listings: IListings }) => {
  // const { listings, isLoading } = useGetFeaturedListings();

  // const FetchListings = () => {
  //   setSize(size + 4);
  // };

  return (
    <Section>
      <SectionContainer maxWidth="lg">
        <SectionHeading>Featured listings</SectionHeading>
        <ListingsSection listings={listings} isLoading={false} />
        {/* {canViewMore && (
          <StyledViewMore
            color="primary"
            disabled={isLoading}
            onClick={FetchListings}
            variant="contained"
          >
            {(isLoading && '...Loading') || 'View more'}
          </StyledViewMore>
        )} */}
      </SectionContainer>
    </Section>
  );
  // return <CardSectionPlaceHolder title="Featured listings" />;
};

export default FeatureListings;
