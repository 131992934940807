import React from 'react';
import styled from 'styled-components';
import Container from '@material-ui/core/Container';
// import Button from '@material-ui/core/Button';
import MuiLink from '@material-ui/core/Link';
import Link from 'next/link';

import useGetLatestListings from 'src/hooks/api/listings/useGetLatestListings';
import { IListings } from 'src/interface';
import ListingsSection from '../Components/ListingsSection';

const Section = styled('section')`
  background: ${({ theme }) => theme.colors.light.primary};
  padding: 40px 0;
  @media (max-width: 450px) {
    padding: 10px 0;
  }
`;
const SectionContainer = styled(Container)`
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
`;
const SectionHeadingContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const SectionHeading = styled('h1')`
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
  @media (max-width: 450px) {
    font-size: 20px;
  }
`;
// const ViewMore = styled(Button)`
//   margin: 20px 0 !important;
//   padding: 10px 20px !important;
// `;

const NewListings = ({ listings }: { listings: IListings }) => {
  // const { listings, isLoading } = useGetLatestListings();

  // const FetchListings = () => {
  //   setSize(size + 4);
  // };

  return (
    <Section>
      <SectionContainer maxWidth="lg">
        <SectionHeadingContainer>
          <SectionHeading>Latest listings</SectionHeading>
          <Link href="/search" prefetch={false} passHref>
            <MuiLink href="/search" color="primary">
              See all
            </MuiLink>
          </Link>
        </SectionHeadingContainer>
        <ListingsSection listings={listings} isLoading={false} />
        {/* {canViewMore && (
          <ViewMore
            color="primary"
            disabled={isLoading}
            onClick={FetchListings}
            variant="contained"
          >
            {(isLoading && '...Loading') || 'View more'}
          </ViewMore>
        )} */}
      </SectionContainer>
    </Section>
  );
};

export default NewListings;
