import React from 'react';
import styled from 'styled-components';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

import useGetWantedListings from 'src/hooks/api/listings/useGetWantedListings';
import MuiLink from '@material-ui/core/Link';
import Link from 'next/link';
import { IListings } from 'src/interface';
import ListingsSection from '../Components/ListingsSection';

const Section = styled('section')`
  background: ${({ theme }) => theme.colors.plainWhite};
  padding: 40px 0;
  @media (max-width: 450px) {
    padding: 10px 0;
  }
`;
const SectionContainer = styled(Container)`
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 0;
`;
const SectionHeadingContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const SectionHeading = styled('h1')`
  font-size: 30px;
  text-align: center;
  text-transform: uppercase;
  @media (max-width: 450px) {
    font-size: 20px;
  }
`;
const ViewMore = styled(Button)`
  margin: 20px 0 !important;
  padding: 10px 20px !important;
`;

const WantedListings = ({ listings }: { listings: IListings }) => {
  // const { listings, isLoading } = useGetWantedListings();

  // const FetchListings = () => {
  //   setSize(size + 4);
  // };

  return (
    <Section>
      <SectionContainer maxWidth="lg">
        <SectionHeadingContainer>
          <SectionHeading>Wanted listings</SectionHeading>
          <Link href="/wanted-search" prefetch={false} passHref>
            <MuiLink href="/wanted-search" color="primary">
              See all
            </MuiLink>
          </Link>
        </SectionHeadingContainer>
        <ListingsSection listings={listings} isWanted isLoading={false} />
        {/* {canViewMore && (
          <ViewMore
            color="primary"
            disabled={isLoading}
            onClick={FetchListings}
            variant="contained"
          >
            {(isLoading && '...Loading') || 'View more'}
          </ViewMore>
        )} */}
      </SectionContainer>
    </Section>
  );
};

export default WantedListings;
